const BASE_URL = process.env.REACT_APP_BASE_API_URL;

const CONFIG = {
  ENV: process.env.REACT_APP_ENVIRONMENT,
  CLOUDFRONT_VIDEOS_URL: process.env.REACT_APP_CLOUDFRONT_VIDEOS_URL,
  BACKEND_API_URL: `${BASE_URL}uia/SelfServicePortal/api`,
  VIDEO_API_URL: `${BASE_URL}stream-videos/KBIUIAStreamVideos/api/aws/video/`,
  X_API_KEY: process.env.REACT_APP_X_API_KEY,
  KT_X_API_KEY: process.env.REACT_APP_KT_X_API_KEY,
  TAXONOMY_CACHE_TIMEOUT: process.env.REACT_APP_TAXONOMY_CACHE_TIMEOUT || 8,
  ADOBE_BASE_URL: process.env.REACT_APP_ADOBE_BASE_URL,
  VIDEO_FILE_SIZE_LIMIT: 104857600,
  THUMBNAIL_FILE_SIZE_LIMIT: 10485760,
  ALLOWED_VIDEO_FILE_TYPES: ["mp4"],
  ALLOWED_THUMBNAIL_FILE_TYPES: ["jpg", "jpeg", "png"],
  PEOPLE_X_API_KEY: process.env.REACT_APP_PEOPLE_X_API_KEY,
  SITECORE_X_API_KEY: process.env.REACT_APP_SITECORE_X_API_KEY,
  PEOPLE_API_URL: process.env.REACT_APP_PEOPLE_API_URL,
  CMP_API_KEY: process.env.REACT_APP_CMP_API_KEY,
  CMP_API_URL: process.env.REACT_APP_CMP_API_URL,
  KT_SCORECARD_REDIRECTION_PARAMS: process.env.REACT_APP_KT_SCORECARD_REDIRECTION_PARAMS,
  BCG_URL: {
    STAFF_IMAGE: hrId =>
      `${process.env.REACT_APP_PEOPLE_API_URL}?hrid=${hrId}`,
    STAFF_DETAIL_URL: staffId =>
      `${process.env.REACT_APP_NAVIGATOR_URL}/Detail/staff_detail.asp?Staff_ID=${staffId}`,
    STAFF_LINK_URL: hrId =>
      `https://profiles.navigator.bcg.com/overview?hrid=${hrId}`,
    SLACK_PROFILE_URL: "slack://user?team=E77AKTKA6&id=",
    PROFILE_NAVIGATOR_URL: "https://profiles.navigator.bcg.com/overview?hrid=",
  },
  OKTA: {
    ISSUER: process.env.REACT_APP_OKTA_URL,
    REDIRECT_URL: '/implicit/callback',
    CLIENT_ID: process.env.REACT_APP_OKTA_CLIENT_ID,
    BASE_URL: process.env.REACT_APP_OKTA_BASE_URL,
    ACCESS_TOKEN: process.env.REACT_APP_TOKEN,
    CLIENT_SECRET: process.env.REACT_APP_CLIENT_SECRET
  },
  APP_CONST: {
    UNAUTHORIZED: '/unauthorized',
    REPORT_LISTING: '/ReportListing',
    REPORT_VIEW: '/reportView',

    ADD_REPORT: 'Add Report',
    EDIT_REPORT: 'Edit Report',
    ADD_REPORT_ACTION: 'Add-Report',
    UAI_NEWS_URL:
      'https://jive.bcg.com/ubertabs-action.jspa?titles=UIA&tabtypes=RC,AC&spaces=700:1677&recursive=N&contenttypes=1,27,38,102&resultcount=10&allContentType=true&allTitle=&viewAllUrls=&alternateftl=/bcg/uber-tabs2.ftl&viewAllUrls=x&showHeader=true',
    NEWS_PAGE_URL: 'News',
    ABOUT_PAGE_URI: 'About',
    FAVORITE_REPORTS_PAGE_URI: 'FavoriteReportList',
    MANAGE_REPORTS_PAGE_URI: 'ManageReports',
    ADD_REPORTS_PAGE_URI: 'AddReport',
    EDIT_REPORTS_PAGE_URI: 'EditReport',
    MANAGE_METRICS_PAGE_URI: 'ManageMetrics',
    MANAGE_TAGS_PAGE_URI: 'ManageTags',
    METRICS_LIST_PAGE_URI: 'MetricsList',
    TAG_LIST_PAGE_URI: 'TagList',
    REPORT_LIST_PAGE_URI: 'ReportListing',
    REPORT_VIEW_PAGE_URI: 'reportView',
    ALL_USERS_FAV_REPORTS_PAGE_URI: 'AllFavoriteReportList',
    SEARCH_REPORT_PAGE_URI: 'SearchedReportsList',
    REPORT_HISTORY_URI: 'ReportHistory',
    HOME_PAGE_URI: '',
    HOME_PAGE_ABSOLUTE_URI: '/',
    KEY_CONTACT: 'KeyContacts',
    ADD_CONTACT: 'AddContact',
    EDIT_CONTACT: 'EditContact',
    HELP_SUPPORT: 'HelpSupport',
    OPEN_IN_NEW_WINDOW: 'open-in-new-window',
    SUGGESTION_AND_FEEDBACK: 'SuggestionFeedback',
    SIDEBAR: 'Sidebar',
    HOME: 'Home',
    SAVE: 'save',
    PUBLISH: 'publish',
    VERSION_HISTORY_PAGE_URI: 'DashboardAudit',
    APP_LEVELS: {
      '/': 1,
      '/TagList': 2,
      '/MetricsList': 2,
      '/ReportList': 2,
      '/FavoriteReportList': 2,
      '/SearchedReportsList': 2,
      '/ReportHistory': 2,
      '/About': 2,
      '/HelpSupport': 2,
      '/KeyContacts': 2,
      '/SuggestionFeedback': 2,
      '/AllFavoriteReportList': 2,
      '/DashboardAudit': 3,
      '/News': 2,
      '/ManageMetrics': 3,
      '/AddContact': 3.1,
      '/EditContact': 3.2,
      '/ManageTags': 3,
      '/AddReport': 3.1,
      '/EditReport': 3.2,
      '/ReportListing': 2,
      '/reportView': 3.3
    },
    PAGE_HEADINGS: {
      '': 'Home',
      upload: 'Upload Video',
      AddReport: 'Add Report',
      EditReport: 'Edit Report',
      TagList: 'Tags',
      MetricsList: 'Metrics & Terms',
      ManageMetrics: 'Add/Edit Metrics & Terms',
      ManageTags: 'Add/Edit Tags',
      ReportListing: 'Dashboard(s)',
      reportView: 'Report View',
      FavoriteReportList: 'Favorite Dashboard(s)',
      SearchedReportsList: 'Searched Result',
      ReportHistory: 'Report History With Filters',
      About: 'About Self-Service Portal',
      HelpSupport: 'Help & Support',
      KeyContacts: 'Key Contacts',
      SuggestionFeedback: 'Suggestions & Feedback',
      AddContact: 'Add Contact',
      EditContact: 'Edit Contact',
      AllFavoriteReportList: 'Favorites by User',
      News: 'News from UIA Community',
      DashboardAudit: 'Dashboard Audit'
    }
  },
  DATE_TIME_FORMAT: {
    API: 'YYYY-MM-DD',
    UI: 'MMM DD, YYYY',
    UI_GTM: 'DD MMM, YY',
    KT_SCORECARD: 'DD MMM YY',
    KT_SCORECARD_DATE_TIME: 'YYYY-MM-DD 00:00:00.000'
  },
  MONTH_FULL_NAMES: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ],
  MONTH_SORT_NAMES: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  API_URLS: {
    ADMIN_DESC: '/admin/description',
    FAV_REPORT: '/user/fav',
    REPORT_COUNT: '/reports/reportCount',
    ABOUT: '/admin/description/about',
    USER_FAV_LIST: '/admin/userFavouriteList',
    SAVE_USER: '/user/saveUser/',
    LATEST_REPORT: '/reports/latest/',
    REPORT_HISTORY: '/reports/history/',
    TAG_LIST: '/tags/',
    METRIC_LIST: '/metrics/',
    SEARCHED_REPORT: '/reports/all',
    RECENT_SEARCH: '/search/recent/',
    REPORTS: '/reports/',
    EDIT_REPORT: '/reports/edit/',
    SEARCH_LISTING: '/search/listing/',
    REPORT_CATEGORY: '/reports/category',
    REPORT_TYPE: '/reports/filters/reportType',
    NEWS: '/admin/description/news',
    FAQ: '/admin/description/faq',
    HELP_SUPPORT: '/admin/description/helpsupport',
    SUGGESTION_AND_FEEDBACK: '/admin/description/suggestionfeedback',
    REQUEST_TRAINING: '/admin/description/requestTraining',
    VIDEO_DESC: '/admin/description/video',
    HOW_IT_WORKS: '/admin/description/howitworks',
    KEY_CONTACT: '/admin/keyContacts',
    KEY_CONTACT_DESC: '/admin/description/keycontacts',
    VIDEO_UPLOAD: 'uploadFile',
    REPORT_STATUS: '/reports/reportStatus/',
    RECENT_VIEW: '/user/viewed/',
    ADMIN_FAQ: '/admin/faq',
    ADMIN_VIDEOS: '/admin/videos',
    ADMIN_KEY_CONTACT: '/admin/keyContacts'
  },
  EXTERNAL_APPLICATION: {
    CMP: 'CMP',
    FILTER_TOPIC_TREE: 'navigator/filters/advance-filters/topicTree',
    STAFF_SEARCH_BY_HRID: 'navigator/staff-search',
    FETCH_USERS_FROM_OKTA_GROUP: (group, searchKey) => `cmp/okta-users?group=${group}&searchKey=${searchKey}`,
    PEOPLE_API: 'peopleApi',
    SITECORE_API: 'bcg_sitecore_apis',
    SUBJECT_TAXONOMY_API: 'taxonomy/{E4E9C7AF-66B0-451B-A65F-F33057207F92}',
    SITECORE_IPA_API: 'taxonomy/{19CC6DD8-E933-4C5D-9898-594FC77498B9}',
    SITECORE_FPA_API: 'taxonomy/{56779BC7-52A4-4B26-A1F3-6F6560D028EC}',
    SITECORE_BCG_INTERNAL_API: 'taxonomy/{ECDF3E3B-C78F-4DE4-9F6E-F9D798DB4DDD}'
  },
  RESTRICTED: 'Restricted',
};
export default CONFIG;
