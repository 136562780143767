export const data = {
    cmp: process.env.REACT_APP_CMP_API_KEY,
    videoBaseUrl: `${process.env.REACT_APP_BASE_API_URL}stream-videos/KBIUIAStreamVideos/api/aws/video`,
    backendAPIUrl: `${process.env.REACT_APP_BASE_API_URL}uia/SelfServicePortal/api`,
    localToken: process.env.REACT_APP_TOKEN,
    people: process.env.REACT_APP_PEOPLE_X_API_KEY,
    sitecore: process.env.REACT_APP_SITECORE_X_API_KEY,
    app: process.env.REACT_APP_X_API_KEY,
    adobe: process.env.REACT_APP_ADOBE_URL,
    cmpUrl: process.env.REACT_APP_CMP_API_URL,
    adobeBaseUrl: process.env.REACT_APP_ADOBE_BASE_URL,
    adbGlobalId: process.env.REACT_APP_ADOBE_GLOBAL_COMPANY_ID,
    adbrsId: process.env.REACT_APP_ADOBE_RSID,
    peopleUrl: process.env.REACT_APP_PEOPLE_API_URL,
    videoCldFrntUrl: process.env.REACT_APP_CLOUDFRONT_VIDEOS_URL
};
